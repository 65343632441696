import { FormField, TextInput } from './Components'

export default function StakeholderData({ errors, defaultValues }) {
  return (
    <>
      <div className="border-b-2 border-gray-400 tablet:mx-0 mx-4">
        <p className="font-bold mb-2 tablet:text-left text-center tablet:text-[18px] text-[16px]">
          Stakeholder's (Proprietor/Partner/Director) Details
        </p>
      </div>

      <FormField label="Name" error={errors.name}>
        <TextInput
          placeholder="ABC Corp"
          name="name"
          required
          defaultValue={defaultValues.name}
        />
      </FormField>

      <FormField label="Email address" error={errors.email}>
        <TextInput
          placeholder="name@example.com"
          name="email"
          type="email"
          required
          defaultValue={defaultValues.email}
        />
      </FormField>

      <FormField label="PAN" error={errors.pan}>
        <TextInput
          placeholder="ABCDE1234F"
          name="pan"
          required
          defaultValue={defaultValues?.kyc?.pan}
        />
      </FormField>

      <FormField label="Random Key" error={errors.random_key}>
        <TextInput
          placeholder="***"
          name="random_key"
          required
          defaultValue={defaultValues?.notes?.random_key}
        />
      </FormField>
    </>
  )
}
