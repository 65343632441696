import React, { useCallback, useEffect, useState } from 'react'
import { patchApiReq, postApiReq } from '../utils/ApiHandler'
import { isYupError, parseYupError } from '../utils/Yup'
import { isLoadingTrue, showToast } from '../redux/action'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { MerchantsValidation } from '../utils/Auth'
import { getAllCategories } from '../utils/basicReq'
import ENV from '../utils/env'
import Loader from '../component/Loader'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { businessCategories } from '../data/businessCategoriesData'
import { useDropzone } from 'react-dropzone'
import { fn } from 'moment/moment'
import MerchantsDataStep1 from './form/MerchantsData'
import StakeholderData from './form/StakeholderData'
import BankData from './form/BankData'

const MerchantsData = ({ mode = 'create' }) => {
  const [flag, setFlag] = useState(true)
  const [merchentId, setMerchentId] = useState('')
  const [image, setImage] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const [errors, setErrors] = useState()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { umid, uid, status, editId } = useParams()

  const [editMerchant, setEditMerchant] = useState({
    // id: 50,
    // user_id: 0,
    // uid: 43,
    // razorpay_account_id: 'acc_PrEk375EK2LuUi',
    // razorpay_product_id: 'acc_prd_PrEpj44gv50HDQ',
    // name: 'Some',
    // email: 'me+block@sayad.dev',
    // logo: '1738585509559.jpg',
    // password: '289bea8a0609b82bc92ee8ca354284bb',
    // poc_name: 'Test',
    // phone: '+912212645678',
    // address:
    //   '{"street1":"234","street2":"234","city":"234","state":"KARNATAKA","postal_code":"560034","country":"IN"}',
    // category: 'financial_services',
    // subcategory: 'mutual_fund',
    // legal_info: '{"pan":"AAACL1254D","gst":"18AAACU9603R1ZZ"}',
    // stakeholder_details:
    //   '{"name":"Name","email":"247sayad@gmail.com","kyc":{"pan":"AFZPK7190K"},"notes":{"random_key":"234234"}}',
    // bank_details:
    //   '{"account_number":"234234234","ifsc_code":"KKBK0000175","beneficiary_name":"234234"}',
    // upi: 'vvv',
    // status: 1,
    // user_type: 'MERCHANT',
    // umid: 'IN17928BG',
    // package: 'free',
    // url: null,
    // created_at: '2025-02-03T12:25:09.000Z',
    // updated_at: null,
  })

  const [addMerchant, setAddMerchants] = useState({})

  const handleForm = async (event) => {
    event.preventDefault()
    const formData = new FormData()
    const formValues = {
      ...extractFormNameValues(event.target),
      language: 'en',
    }

    for (const key in formValues) {
      formData.append(key, formValues[key])
    }

    try {
      setErrors({})
      setIsLoading(true)

      if (steps === 1) {
        formData.delete('logo')
        const logoFile = event.target.logo?.files?.[0]
        if (logoFile) {
          formData.append('logo', logoFile)
        } else {
          setErrors((prev) => ({ ...prev, logo: 'Please upload logo' }))
        }

        if (mode === 'create') {
          await MerchantsValidation.validate(formValues, {
            abortEarly: false,
          })
        }

        ;(mode === 'edit' ? patchApiReq : postApiReq)(
          `/admin/merchants/accounts` +
            (mode === 'edit' ? `/${editMerchant.id}` : ''),
          formData
        )
          .then((res) => {
            if (!res.status) {
              setIsLoading(false)
              dispatch(
                showToast({
                  type: 'error',
                  message:
                    res.error?.errors?.error?.description ??
                    res.error?.error?.description ??
                    res.error?.errors?.message ??
                    res.error?.error?.message ??
                    res.error?.message,
                })
              )
            } else {
              if (res.status) {
                setAddMerchants(res.data)
                console.log({ data: res.data })

                dispatch(
                  showToast({
                    type: 'success',
                    message: 'Merchant Data Added successfully.',
                  })
                )

                setSteps(2)
                setIsLoading(false)
              } else {
                setIsLoading(false)
                dispatch(
                  showToast({
                    type: 'error',
                    message:
                      res.error?.errors?.error?.description ??
                      res.error?.error?.description ??
                      res.error?.errors?.message ??
                      res.error?.error?.message ??
                      res.error?.message,
                  })
                )
              }
            }
          })
          .catch((e) => {
            setIsLoading(false)
            console.log('error', e)
          })
      } else if (steps === 2) {
        const hasStakeholder =
          addMerchant.stakeholder_details ?? editMerchant.stakeholder_details

        ;(hasStakeholder ? patchApiReq : postApiReq)(
          `/admin/merchants/accounts/${
            addMerchant.id ?? editMerchant.id
          }/stakeholders`,
          formValues
        )
          .then((res) => {
            if (!res.status) {
              setIsLoading(false)
              dispatch(
                showToast({
                  type: 'error',
                  message:
                    res.error?.errors?.error?.description ??
                    res.error?.error?.description ??
                    res.error?.errors?.message ??
                    res.error?.error?.message ??
                    res.error?.message,
                })
              )
            } else {
              console.log(res.status)
              if (res.status) {
                dispatch(
                  showToast({
                    type: 'success',
                    message: 'Stakeholder Data Added successfully.',
                  })
                )

                setSteps(3)
                setIsLoading(false)
              } else {
                setIsLoading(false)
                dispatch(
                  showToast({
                    type: 'error',
                    message:
                      res.error?.errors?.error?.description ??
                      res.error?.error?.description ??
                      res.error?.errors?.message ??
                      res.error?.error?.message ??
                      res.error?.message,
                  })
                )
              }
            }
          })
          .catch((e) => {
            setIsLoading(false)
            console.log('error', e)
          })
      } else if (steps === 3) {
        patchApiReq(
          `/admin/merchants/accounts/${
            addMerchant.id ?? editMerchant.id
          }/products`,
          formValues
        )
          .then((res) => {
            if (!res.status) {
              setIsLoading(false)
              dispatch(
                showToast({
                  type: 'error',
                  message:
                    res.error?.errors?.error?.description ??
                    res.error?.error?.description ??
                    res.error?.errors?.message ??
                    res.error?.error?.message ??
                    res.error?.message,
                })
              )
            } else {
              console.log(res.status)
              if (res.status) {
                dispatch(
                  showToast({
                    type: 'success',
                    message: 'Bank Data Added successfully.',
                  })
                )

                setIsLoading(false)
              } else {
                setIsLoading(false)
                dispatch(
                  showToast({
                    type: 'error',
                    message:
                      res.error?.errors?.error?.description ??
                      res.error?.error?.description ??
                      res.error?.errors?.message ??
                      res.error?.error?.message ??
                      res.error?.message,
                  })
                )
              }
            }
          })
          .catch((e) => {
            setIsLoading(false)
            console.log('error', e)
          })
      }
    } catch (error) {
      setIsLoading(false)

      if (isYupError(error)) {
        console.log(parseYupError(error))
        setErrors(parseYupError(error))
      } else {
        console.log('error')
      }
    }
  }

  // const statusApiCall = () => {
  //   postApiReq(`/admin/get-qr-code-detail-status`, {
  //     merchant_uid: Number(uid),
  //     merchant_code_status: Number(1),
  //   })
  //     .then((res) => {
  //     })
  //     .catch((e) => {
  //       console.log("error", e);
  //     });
  // };

  // ------------------------------------merchantQR page Edit Button Api call----------------
  useEffect(() => {
    if (uid || editId) {
      if (editId && status && umid) {
        getEditData()
        setFlag(false)
      } else if (umid && uid && status) {
        setFlag(true)
      } else if (uid) {
        getUmidEdit()
        setFlag(false)
      }
    } else {
      setFlag(true)
    }
  }, [uid, editId])

  useEffect(() => {
    //setCategories(getAllCategories());
  }, [])
  const getUmidEdit = () => {
    postApiReq(`/admin/edit-merchant-umid`, { merchant_uid: uid ?? editId })
      .then((res) => {
        setMerchentId(res.data.data[0].merchant_id)
        setImage(res.data.data[0].merchant_logo)
        setEditMerchant(res.data.data[0])
      })
      .catch((e) => {
        console.log('error', e)
      })
  }

  // ----------------------------------get Edit data api Calling--------------------------

  const getEditData = () => {
    postApiReq(`/admin/edit-merchant`, { id: editId })
      .then((res) => {
        setImage(res.data.data[0].merchant_logo)
        setAddMerchants({
          merchant_name: res.data.data[0].merchant_name,
          merchant_email: res.data.data[0].merchant_email,
          merchant_location: res.data.data[0].merchant_location,
          merchant_poc_name: res.data.data[0].merchant_poc_name,
          merchant_contact: res.data.data[0].merchant_contact,
          merchant_category: res.data.data[0].merchant_category,
          merchant_logo: res.data.data[0].merchant_logo,
          merchant_pan: res.data.data[0].merchant_pan,
          merchant_gst: res.data.data[0].merchant_gst,
          merchant_url: res.data.data[0].merchant_url,
          merchant_upi: res.data.data[0].merchant_upi,
          merchant_umid: umid,
          merchant_status: status,
          merchant_package: res.data.data[0].merchant_package,
          business_type: res.data.data[0].business_type,
          ifsc_code: res.data.data[0].ifsc_code,
          account_holder_name: res.data.data[0].account_holder_name,
          account_number: res.data.data[0].account_number,
          bank_name: res.data.data[0].bank_name,
          state: res.data.data[0].state,
          city: res.data.data[0].city,
          address: res.data.data[0].address,
          area_name: res.data.data[0].area_name,
          pin_code: res.data.data[0].pin_code,
        })
      })
      .catch((e) => {
        console.log('error', e)
      })
  }

  // const [showModal, setShowModal] = useState(false)
  const [steps, setSteps] = useState(1)
  const handlePrev = () => {
    if (steps > 1) {
      setSteps(steps - 1)
    }
  }

  const handleNext = () => {
    if (steps < 3) {
      setSteps(steps + 1)
    }
  }

  useEffect(() => {
    setErrors({})
  }, [steps])

  useEffect(() => {
    if (mode === 'edit') {
      getUmidEdit()
    }
  }, [])

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className="bg-gray-200 min-h-screen w-full text-[18px] py-12 pt-4 relative">
        <form method="post" action="#" onSubmit={handleForm}>
          <div className="max-w-[1200px] mx-auto px-4 flex items-center justify-between">
            <button onClick={() => navigate('/merchantstab')}>
              <IoMdArrowRoundBack className="text-[30px]" />
            </button>
            {/* <button
              onClick={() => setShowModal(true)}
              className="px-5 py-3 border border-black capitalize"
            >
              modal
            </button> */}
          </div>
          <div className="w-full tablet:text-[18px] text-[15px] max-w-[1200px] gap-20 mx-auto flex lg:justify-between tablet:justify-center lg:items-start tablet:items-center flex-col">
            <div className="gap-6 tablet:w-[80%] lg:w-[100%] w-full px-4 mx-auto flex flex-col tablet:text-left">
              {mode === 'edit' && !editMerchant ? (
                <div>Content is loading...</div>
              ) : (
                <>
                  {steps === 1 && (
                    <MerchantsDataStep1
                      mode={mode}
                      errors={errors ?? {}}
                      defaultValues={editMerchant}
                    />
                  )}
                  {steps === 2 && (
                    <StakeholderData
                      mode={mode}
                      errors={errors ?? {}}
                      defaultValues={
                        typeof editMerchant.stakeholder_details === 'string'
                          ? JSON.parse(editMerchant.stakeholder_details)
                          : editMerchant.stakeholder_details ?? {}
                      }
                    />
                  )}
                  {steps === 3 && (
                    <BankData
                      mode={mode}
                      errors={errors ?? {}}
                      id={addMerchant.id ?? editMerchant.id}
                      defaultValues={
                        typeof editMerchant.bank_details === 'string'
                          ? JSON.parse(editMerchant.bank_details)
                          : editMerchant.bank_details ?? {}
                      }
                    />
                  )}
                </>
              )}

              {/* third div end */}
              <div className="w-full">
                <div className="flex gap-6 w-fit ml-auto">
                
                 { steps > 1 && 
                  <button
                      type={'button'}
                      onClick={handlePrev}
                      className="px-5 py-3 border border-black capitalize"
                    >
                      prev
                    </button>
                  }

                  {(steps !== 1 || mode === 'edit') && (
                    <button
                      type={'button'}
                      onClick={handleNext}
                      className="px-5 py-3 border border-black capitalize"
                    >
                      next
                    </button>
                  )}

                  <button className="px-7 py-3 border bg-black text-white capitalize">
                    {mode === 'edit' ? 'Update' : mode}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        {/* {showModal && <Modal handleCloseModal={() => setShowModal(false)} />} */}
      </div>
    </>
  )
}
export default MerchantsData

const Modal = ({ handleCloseModal }) => {
  const [logoFiles, setLogoFiles] = useState([])
  const handleLogoDrop = useCallback((acceptedFiles) => {
    setLogoFiles((prev) => [...prev, ...acceptedFiles])
  }, [])

  const logoDropzone = useDropzone({
    onDrop: handleLogoDrop,
    accept: { 'image/*': ['.jpg', '.jpeg', '.png', '.gif', '.svg'] },
    multiple: true,
  })
  return (
    <>
      <section
        onClick={handleCloseModal}
        className="fixed min-h-screen w-screen bg-black/60 backdrop-blur-sm top-0 flex items-center justify-center p-3"
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="bg-white p-6 rounded-2xl h-[500px] max-w-[600px] w-full"
        >
          just a modal
          <div>
            <label>logo</label>
            <div
              {...logoDropzone.getRootProps()}
              className="border border-black w-40 h-40 rounded-lg text-[80px] flex justify-center items-center relative cursor-pointer"
            >
              <p>+</p>
              <input
                {...logoDropzone.getInputProps()}
                type="file"
                className="absolute h-full w-full opacity-0"
              />
            </div>
            {logoFiles.length > 0 && (
              <div className="mt-4 relative w-max">
                <img
                  src={URL.createObjectURL(logoFiles[0])}
                  alt="Logo Preview"
                  className="w-[100px] h-[100px] object-contain rounded-md"
                />
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

function extractFormNameValues(form) {
  const formValues = {}
  for (let i = 0; i < form.elements.length; i++) {
    const element = form.elements[i]
    if (element.name) {
      formValues[element.name] = element.value
    }
  }

  return formValues
}
