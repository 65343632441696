export const businessCategories=[
    {
        id: 1,
        label: "Financial Services",
        value: "financial_services",
        parent_id: 0
    },
    {
        id: 2,
        label: "Mutual Fund",
        value: "mutual_fund",
        parent_id: 1
    },
    {
        id: 3,
        label: "Lending",
        value: "lending",
        parent_id: 1
    },
    {
        id: 4,
        label: "Cryptocurrency",
        value: "cryptocurrency",
        parent_id: 1
    },
    {
        id: 5,
        label: "Insurance",
        value: "insurance",
        parent_id: 1
    },
    {
        id: 6,
        label: "NBFC",
        value: "nbfc",
        parent_id: 1
    },
    {
        id: 7,
        label: "Cooperatives",
        value: "cooperatives",
        parent_id: 1
    },
    {
        id: 8,
        label: "Pension Fund",
        value: "pension_fund",
        parent_id: 1
    },
    {
        id: 9,
        label: "Forex",
        value: "forex",
        parent_id: 1
    },
    {
        id: 10,
        label: "Securities",
        value: "securities",
        parent_id: 1
    },
    {
        id: 11,
        label: "Commodities",
        value: "commodities",
        parent_id: 1
    },
    {
        id: 12,
        label: "Accounting",
        value: "accounting",
        parent_id: 1
    },
    {
        id: 13,
        label: "Financial Advisor",
        value: "financial_advisor",
        parent_id: 1
    },
    {
        id: 14,
        label: "Crowdfunding",
        value: "crowdfunding",
        parent_id: 1
    },
    {
        id: 15,
        label: "Trading",
        value: "trading",
        parent_id: 1
    },
    {
        id: 16,
        label: "Betting",
        value: "betting",
        parent_id: 1
    },
    {
        id: 17,
        label: "Get Rich Schemes",
        value: "get_rich_schemes",
        parent_id: 1
    },
    {
        id: 18,
        label: "MoneySend Funding",
        value: "moneysend_funding",
        parent_id: 1
    },
    {
        id: 19,
        label: "Wire Transfers and Money Orders",
        value: "wire_transfers_and_money_orders",
        parent_id: 1
    },
    {
        id: 20,
        label: "Tax Preparation Services",
        value: "tax_preparation_services",
        parent_id: 1
    },
    {
        id: 21,
        label: "Tax Payments",
        value: "tax_payments",
        parent_id: 1
    },
    {
        id: 22,
        label: "Digital Goods",
        value: "digital_goods",
        parent_id: 1
    },
    {
        id: 23,
        label: "ATMs",
        value: "atms",
        parent_id: 1
    },
    {
        id: 24,
        label: "Education",
        value: "education",
        parent_id: 0
    },
    {
        id: 25,
        label: "College",
        value: "college",
        parent_id: 24
    },
    {
        id: 26,
        label: "Schools",
        value: "schools",
        parent_id: 24
    },
    {
        id: 27,
        label: "University",
        value: "university",
        parent_id: 24
    },
    {
        id: 28,
        label: "Professional Courses",
        value: "professional_courses",
        parent_id: 24
    },
    {
        id: 29,
        label: "Distance Learning",
        value: "distance_learning",
        parent_id: 24
    },
    {
        id: 30,
        label: "Day Care",
        value: "day_care",
        parent_id: 24
    },
    {
        id: 31,
        label: "Coaching",
        value: "coaching",
        parent_id: 24
    },
    {
        id: 32,
        label: "E-learning",
        value: "elearning",
        parent_id: 24
    },
    {
        id: 33,
        label: "Vocational and Trade Schools",
        value: "vocational_and_trade_schools",
        parent_id: 24
    },
    {
        id: 34,
        label: "Sporting Clubs",
        value: "sporting_clubs",
        parent_id: 24
    },
    {
        id: 35,
        label: "Dance Halls, Studios, and Schools",
        value: "dance_halls_studios_and_schools",
        parent_id: 24
    },
    {
        id: 36,
        label: "Correspondence Schools",
        value: "correspondence_schools",
        parent_id: 24
    },
    {
        id: 37,
        label: "Healthcare",
        value: "healthcare",
        parent_id: 0
    },
    {
        id: 38,
        label: "Pharmacy",
        value: "pharmacy",
        parent_id: 37
    },
    {
        id: 39,
        label: "Clinic",
        value: "clinic",
        parent_id: 37
    },
    {
        id: 40,
        label: "Hospital",
        value: "hospital",
        parent_id: 37
    },
    {
        id: 41,
        label: "Lab",
        value: "lab",
        parent_id: 37
    },
    {
        id: 42,
        label: "Dietician",
        value: "dietician",
        parent_id: 37
    },
    {
        id: 43,
        label: "Fitness",
        value: "fitness",
        parent_id: 37
    },
    {
        id: 44,
        label: "Health Coaching",
        value: "health_coaching",
        parent_id: 37
    },
    {
        id: 45,
        label: "Health Products",
        value: "health_products",
        parent_id: 37
    },
    {
        id: 46,
        label: "Drug Stores",
        value: "drug_stores",
        parent_id: 37
    },
    {
        id: 47,
        label: "Healthcare Marketplace",
        value: "healthcare_marketplace",
        parent_id: 37
    },
    {
        id: 48,
        label: "Osteopaths",
        value: "osteopaths",
        parent_id: 37
    },
    {
        id: 49,
        label: "Medical Equipment and Supply Stores",
        value: "medical_equipment_and_supply_stores",
        parent_id: 37
    },
    {
        id: 50,
        label: "Podiatrists and Chiropodists",
        value: "podiatrists_and_chiropodists",
        parent_id: 37
    },
    {
        id: 51,
        label: "Dentists and Orthodontists",
        value: "dentists_and_orthodontists",
        parent_id: 37
    },
    {
        id: 52,
        label: "Hardware Stores",
        value: "hardware_stores",
        parent_id: 37
    },
    {
        id: 53,
        label: "Ophthalmologists",
        value: "ophthalmologists",
        parent_id: 37
    },
    {
        id: 54,
        label: "Orthopedic Goods Stores",
        value: "orthopedic_goods_stores",
        parent_id: 37
    },
    {
        id: 55,
        label: "Testing Laboratories",
        value: "testing_laboratories",
        parent_id: 37
    },
    {
        id: 56,
        label: "Doctors",
        value: "doctors",
        parent_id: 37
    },
    {
        id: 57,
        label: "Health Practitioners (Medical Services)",
        value: "health_practitioners_medical_services",
        parent_id: 37
    },
    {
        id: 58,
        label: "Testing Laboratories",
        value: "testing_laboratories",
        parent_id: 37
    },
    {
        id: 59,
        label: "Ecommerce",
        value: "ecommerce",
        parent_id: 0
    },
    {
        id: 60,
        label: "Ecommerce Marketplace",
        value: "ecommerce_marketplace",
        parent_id: 59
    },
    {
        id: 61,
        label: "Agriculture",
        value: "agriculture",
        parent_id: 59
    },
    {
        id: 62,
        label: "Books",
        value: "books",
        parent_id: 59
    },
    {
        id: 63,
        label: "Electronics and Furniture",
        value: "electronics_and_furniture",
        parent_id: 59
    },
    {
        id: 64,
        label: "Coupons",
        value: "coupons",
        parent_id: 59
    },
    {
        id: 65,
        label: "Rental",
        value: "rental",
        parent_id: 59
    },
    {
        id: 66,
        label: "Fashion and Lifestyle",
        value: "fashion_and_lifestyle",
        parent_id: 59
    },
    {
        id: 67,
        label: "Gifting",
        value: "gifting",
        parent_id: 59
    },
    {
        id: 68,
        label: "Grocery",
        value: "grocery",
        parent_id: 59
    },
    {
        id: 69,
        label: "Baby Products",
        value: "baby_products",
        parent_id: 59
    },
    {
        id: 70,
        label: "Office Supplies",
        value: "office_supplies",
        parent_id: 59
    },
    {
        id: 71,
        label: "Wholesale",
        value: "wholesale",
        parent_id: 59
    },
    {
        id: 72,
        label: "Religious Products",
        value: "religious_products",
        parent_id: 59
    },
    {
        id: 73,
        label: "Pet Products",
        value: "pet_products",
        parent_id: 59
    },
    {
        id: 74,
        label: "Sports Products",
        value: "sports_products",
        parent_id: 59
    },
    {
        id: 75,
        label: "Arts and Collectibles",
        value: "arts_and_collectibles",
        parent_id: 59
    },
    {
        id: 76,
        label: "Sexual Wellness Products",
        value: "sexual_wellness_products",
        parent_id: 59
    },
    {
        id: 77,
        label: "Drop Shipping",
        value: "drop_shipping",
        parent_id: 59
    },
    {
        id: 78,
        label: "Crypto Machinery",
        value: "crypto_machinery",
        parent_id: 59
    },
    {
        id: 79,
        label: "Tobacco",
        value: "tobacco",
        parent_id: 59
    },
    {
        id: 80,
        label: "Weapons and Ammunitions",
        value: "weapons_and_ammunitions",
        parent_id: 59
    },
    {
        id: 81,
        label: "Stamps and Coins Stores",
        value: "stamps_and_coins_stores",
        parent_id: 59
    },
    {
        id: 82,
        label: "Office Equipment",
        value: "office_equipment",
        parent_id: 59
    },
    {
        id: 83,
        label: "Automobile Parts and Equipments",
        value: "automobile_parts_and_equipements",
        parent_id: 59
    },
    {
        id: 84,
        label: "Garden Supply Stores",
        value: "garden_supply_stores",
        parent_id: 59
    },
    {
        id: 85,
        label: "Household Appliance Stores",
        value: "household_appliance_stores",
        parent_id: 59
    },
    {
        id: 86,
        label: "Non Durable Goods",
        value: "non_durable_goods",
        parent_id: 59
    },
    {
        id: 87,
        label: "Pawn Shops",
        value: "pawn_shops",
        parent_id: 59
    },
    {
        id: 88,
        label: "Electrical Parts and Equipment",
        value: "electrical_parts_and_equipment",
        parent_id: 59
    },
    {
        id: 89,
        label: "Wig and Toupee Shops",
        value: "wig_and_toupee_shops",
        parent_id: 59
    },
    {
        id: 90,
        label: "Gift Novelty and Souvenir Shops",
        value: "gift_novelty_and_souvenir_shops",
        parent_id: 59
    },
    {
        id: 91,
        label: "Duty Free Stores",
        value: "duty_free_stores",
        parent_id: 59
    },
    {
        id: 92,
        label: "Office and Commercial Furniture",
        value: "office_and_commercial_furniture",
        parent_id: 59
    },
    {
        id: 93,
        label: "Dry Goods",
        value: "dry_goods",
        parent_id: 59
    },
    {
        id: 94,
        label: "Books and Publications",
        value: "books_and_publications",
        parent_id: 59
    },
    {
        id: 95,
        label: "Camera and Photographic Stores",
        value: "camera_and_photographic_stores",
        parent_id: 59
    },
    {
        id: 96,
        label: "Record Shops",
        value: "record_shops",
        parent_id: 59
    },
    {
        id: 97,
        label: "Meat Supply Stores",
        value: "meat_supply_stores",
        parent_id: 59
    },
    {
        id: 98,
        label: "Leather Goods and Luggage",
        value: "leather_goods_and_luggage",
        parent_id: 59
    },
    {
        id: 99,
        label: "Snowmobile Dealers",
        value: "snowmobile_dealers",
        parent_id: 59
    },
    {
        id: 100,
        label: "Men and Boys Clothing Stores",
        value: "men_and_boys_clothing_stores",
        parent_id: 59
    },
    {
        id: 101,
        label: "Paint Supply Stores",
        value: "paint_supply_stores",
        parent_id: 59
    },
    {
        id: 102,
        label: "Automotive Parts",
        value: "automotive_parts",
        parent_id: 59
    },
    {
        id: 103,
        label: "Jewellery and Watch Stores",
        value: "jewellery_and_watch_stores",
        parent_id: 59
    },
    {
        id: 104,
        label: "Auto Store and Home Supply Stores",
        value: "auto_store_home_supply_stores",
        parent_id: 59
    },
    {
        id: 105,
        label: "Tent Stores",
        value: "tent_stores",
        parent_id: 59
    },
    {
        id: 106,
        label: "Shoe Stores Retail",
        value: "shoe_stores_retail",
        parent_id: 59
    },
    {
        id: 107,
        label: "Petroleum and Petroleum Products",
        value: "petroleum_and_petroleum_products",
        parent_id: 59
    },
    {
        id: 108,
        label: "Department Stores",
        value: "department_stores",
        parent_id: 59
    },
    {
        id: 109,
        label: "Automotive Tire Stores",
        value: "automotive_tire_stores",
        parent_id: 59
    },
    {
        id: 110,
        label: "Sport Apparel Stores",
        value: "sport_apparel_stores",
        parent_id: 59
    },
    {
        id: 111,
        label: "Variety Stores",
        value: "variety_stores",
        parent_id: 59
    },
    {
        id: 112,
        label: "Chemicals and Allied Products",
        value: "chemicals_and_allied_products",
        parent_id: 59
    },
    {
        id: 113,
        label: "Commercial Equipments",
        value: "commercial_equipments",
        parent_id: 59
    },
    {
        id: 114,
        label: "Fireplace Parts and Accessories",
        value: "fireplace_parts_and_accessories",
        parent_id: 59
    },
    {
        id: 115,
        label: "Family Clothing Stores",
        value: "family_clothing_stores",
        parent_id: 59
    },
    {
        id: 116,
        label: "Fabric and Sewing Stores",
        value: "fabric_and_sewing_stores",
        parent_id: 59
    },
    {
        id: 117,
        label: "Home Supply Warehouse",
        value: "home_supply_warehouse",
        parent_id: 59
    },
    {
        id: 118,
        label: "Art Supply Stores",
        value: "art_supply_stores",
        parent_id: 59
    },
    {
        id: 119,
        label: "Camper, Recreational, and Utility Trailer Dealers",
        value: "camper_recreational_and_utility_trailer_dealers",
        parent_id: 59
    },
    {
        id: 120,
        label: "Clocks and Silverware Stores",
        value: "clocks_and_silverware_stores",
        parent_id: 59
    },
    {
        id: 121,
        label: "Discount Stores",
        value: "discount_stores",
        parent_id: 59
    },
    {
        id: 122,
        label: "School Supplies and Stationery",
        value: "school_supplies_and_stationery",
        parent_id: 59
    },
    {
        id: 123,
        label: "Second Hand Stores",
        value: "second_hand_stores",
        parent_id: 59
    },
    {
        id: 124,
        label: "Watch and Jewellery Repair Stores",
        value: "watch_and_jewellery_repair_stores",
        parent_id: 59
    },
    {
        id: 125,
        label: "Liquor Stores",
        value: "liquor_stores",
        parent_id: 59
    },
    {
        id: 126,
        label: "Boat Dealers",
        value: "boat_dealers",
        parent_id: 59
    },
    {
        id: 127,
        label: "Opticians, Optical Goods and Eyeglasses Stores",
        value: "opticians_optical_goods_and_eyeglasse_stores",
        parent_id: 59
    },
    {
        id: 128,
        label: "Wholesale Footwear Stores",
        value: "wholesale_footwear_stores",
        parent_id: 59
    },
    {
        id: 129,
        label: "Cosmetic Stores",
        value: "cosmetic_stores",
        parent_id: 59
    },
    {
        id: 130,
        label: "Home Furnishing Stores",
        value: "home_furnishing_stores",
        parent_id: 59
    },
    {
        id: 131,
        label: "Antique Stores",
        value: "antique_stores",
        parent_id: 59
    },
    {
        id: 132,
        label: "Plumbing and Heating Equipment",
        value: "plumbing_and_heating_equipment",
        parent_id: 59
    },
    {
        id: 133,
        label: "Telecommunication Equipment Stores",
        value: "telecommunication_equipment_stores",
        parent_id: 59
    },
    {
        id: 134,
        label: "Women Clothing",
        value: "women_clothing",
        parent_id: 59
    },
    {
        id: 135,
        label: "Florists",
        value: "florists",
        parent_id: 59
    },
    {
        id: 136,
        label: "Computer Software Stores",
        value: "computer_software_stores",
        parent_id: 59
    },
    {
        id: 137,
        label: "Building Material Stores",
        value: "building_matrial_stores",
        parent_id: 59
    },
    {
        id: 138,
        label: "Candy, Nut, Confectionery Shops",
        value: "candy_nut_confectionery_shops",
        parent_id: 59
    },
    {
        id: 139,
        label: "Glass and Wallpaper Stores",
        value: "glass_and_wallpaper_stores",
        parent_id: 59
    },
    {
        id: 140,
        label: "Commercial Photography and Graphic Design Services",
        value: "commercial_photography_and_graphic_design_services",
        parent_id: 59
    },
    {
        id: 141,
        label: "Video Game Supply Stores",
        value: "video_game_supply_stores",
        parent_id: 59
    },
    {
        id: 142,
        label: "Fuel Dealers",
        value: "fuel_dealers",
        parent_id: 59
    },
    {
        id: 143,
        label: "Drapery and Window Coverings Stores",
        value: "drapery_and_window_coverings_stores",
        parent_id: 59
    },
    {
        id: 144,
        label: "Hearing Aids Stores",
        value: "hearing_aids_stores",
        parent_id: 59
    },
    {
        id: 145,
        label: "Automotive Paint Shops",
        value: "automotive_paint_shops",
        parent_id: 59
    },
    {
        id: 146,
        label: "Durable Goods Stores",
        value: "durable_goods_stores",
        parent_id: 59
    },
    {
        id: 147,
        label: "Uniforms and Commercial Clothing Stores",
        value: "uniforms_and_commercial_clothing_stores",
        parent_id: 59
    },
    {
        id: 148,
        label: "Fur Shops",
        value: "fur_shops",
        parent_id: 59
    },
    {
        id: 149,
        label: "Industrial Supplies",
        value: "industrial_supplies",
        parent_id: 59
    },
    {
        id: 150,
        label: "Bicycle Stores",
        value: "bicycle_stores",
        parent_id: 59
    },
    {
        id: 151,
        label: "Motorcycle Shops and Dealers",
        value: "motorcycle_shops_and_dealers",
        parent_id: 59
    },
    {
        id: 152,
        label: "Children and Infants Wear Stores",
        value: "children_and_infants_wear_stores",
        parent_id: 59
    },
    {
        id: 153,
        label: "Women Accessory Stores",
        value: "women_accessory_stores",
        parent_id: 59
    },
    {
        id: 154,
        label: "Construction Materials",
        value: "construction_materials",
        parent_id: 59
    },
    {
        id: 155,
        label: "Books, Periodicals, and Newspapers",
        value: "books_periodicals_and_newspaper",
        parent_id: 59
    },
    {
        id: 156,
        label: "Floor Covering Stores",
        value: "floor_covering_stores",
        parent_id: 59
    },
    {
        id: 157,
        label: "Crystal and Glassware Stores",
        value: "crystal_and_glassware_stores",
        parent_id: 59
    },
    {
        id: 158,
        label: "Accessory and Apparel Stores",
        value: "accessory_and_apparel_stores",
        parent_id: 59
    },
    {
        id: 159,
        label: "Hardware Equipment and Supply Stores",
        value: "hardware_equipment_and_supply_stores",
        parent_id: 59
    },
    {
        id: 160,
        label: "Computers, Peripheral Equipment, Software",
        value: "computers_peripheral_equipment_software",
        parent_id: 59
    },
    {
        id: 161,
        label: "Automobile and Truck Dealers",
        value: "automobile_and_truck_dealers",
        parent_id: 59
    },
    {
        id: 162,
        label: "Aircraft and Farm Equipment Dealers",
        value: "aircraft_and_farm_equipment_dealers",
        parent_id: 59
    },
    {
        id: 163,
        label: "Antique Shops, Sales and Repairs",
        value: "antique_shops_sales_and_repairs",
        parent_id: 59
    },
    {
        id: 164,
        label: "Hearing Aids Stores",
        value: "hearing_aids_stores",
        parent_id: 59
    },
    {
        id: 165,
        label: "Music Stores",
        value: "music_stores",
        parent_id: 59
    },
    {
        id: 166,
        label: "Furniture and Home Furnishing Stores",
        value: "furniture_and_home_furnishing_store",
        parent_id: 59
    },
    {
        id: 167,
        label: "Services",
        value: "services",
        parent_id: 0
    },
    {
        id: 168,
        label: "Repair and Cleaning",
        value: "repair_and_cleaning",
        parent_id: 167
    },
    {
        id: 169,
        label: "Interior Design and Architect",
        value: "interior_design_and_architect",
        parent_id: 167
    },
    {
        id: 170,
        label: "Movers and Packers",
        value: "movers_and_packers",
        parent_id: 167
    },
    {
        id: 171,
        label: "Legal",
        value: "legal",
        parent_id: 167
    },
    {
        id: 172,
        label: "Event Planning",
        value: "event_planning",
        parent_id: 167
    },
    {
        id: 173,
        label: "Service Centre",
        value: "service_centre",
        parent_id: 167
    },
    {
        id: 174,
        label: "Consulting",
        value: "consulting",
        parent_id: 167
    },
    {
        id: 175,
        label: "Ad and Marketing",
        value: "ad_and_marketing",
        parent_id: 167
    },
    {
        id: 176,
        label: "Services Classifieds",
        value: "services_classifieds",
        parent_id: 167
    },
    {
        id: 177,
        label: "Multi Level Marketing",
        value: "multi_level_marketing",
        parent_id: 167
    },
    {
        id: 178,
        label: "Construction Services",
        value: "construction_services",
        parent_id: 167
    },
    {
        id: 179,
        label: "Architectural Services",
        value: "architectural_services",
        parent_id: 167
    },
    {
        id: 180,
        label: "Car Washes",
        value: "car_washes",
        parent_id: 167
    },
    {
        id: 181,
        label: "Motor Home Rentals",
        value: "motor_home_rentals",
        parent_id: 167
    },
    {
        id: 182,
        label: "Stenographic and Secretarial Support Services",
        value: "stenographic_and_secretarial_support_services",
        parent_id: 167
    },
    {
        id: 183,
        label: "Chiropractors",
        value: "chiropractors",
        parent_id: 167
    },
    {
        id: 184,
        label: "Automotive Service Shops",
        value: "automotive_service_shops",
        parent_id: 167
    },
    {
        id: 185,
        label: "Shoe Repair Shops",
        value: "shoe_repair_shops",
        parent_id: 167
    },
    {
        id: 186,
        label: "Telecommunication Service",
        value: "telecommunication_service",
        parent_id: 167
    },
    {
        id: 187,
        label: "Fines",
        value: "fines",
        parent_id: 167
    },
    {
        id: 188,
        label: "Security Agencies",
        value: "security_agencies",
        parent_id: 167
    },
    {
        id: 189,
        label: "Tailors",
        value: "tailors",
        parent_id: 167
    },
    {
        id: 190,
        label: "Type Setting and Engraving Services",
        value: "type_setting_and_engraving_services",
        parent_id: 167
    },
    {
        id: 191,
        label: "Small Appliance Repair Shops",
        value: "small_appliance_repair_shops",
        parent_id: 167
    },
    {
        id: 192,
        label: "Photography Labs",
        value: "photography_labs",
        parent_id: 167
    },
    {
        id: 193,
        label: "Dry Cleaners",
        value: "dry_cleaners",
        parent_id: 167
    },
    {
        id: 194,
        label: "Massage Parlors",
        value: "massage_parlors",
        parent_id: 167
    },
    {
        id: 195,
        label: "Electronic Repair Shops",
        value: "electronic_repair_shops",
        parent_id: 167
    },
    {
        id: 196,
        label: "Cleaning and Sanitation Services",
        value: "cleaning_and_sanitation_services",
        parent_id: 167
    },
    {
        id: 197,
        label: "Nursing Care Facilities",
        value: "nursing_care_facilities",
        parent_id: 167
    },
    {
        id: 198,
        label: "Direct Marketing",
        value: "direct_marketing",
        parent_id: 167
    },
    {
        id: 199,
        label: "Lottery",
        value: "lottery",
        parent_id: 167
    },
    {
        id: 200,
        label: "Veterinary Services",
        value: "veterinary_services",
        parent_id: 167
    },
    {
        id: 201,
        label: "Affiliated Auto Rental",
        value: "affliated_auto_rental",
        parent_id: 167
    },
    {
        id: 202,
        label: "Alimony and Child Support",
        value: "alimony_and_child_support",
        parent_id: 167
    },
    {
        id: 203,
        label: "Airport Flying Fields",
        value: "airport_flying_fields",
        parent_id: 167
    },
    {
        id: 204,
        label: "Golf Courses",
        value: "golf_courses",
        parent_id: 167
    },
    {
        id: 205,
        label: "Tire Retreading and Repair Shops",
        value: "tire_retreading_and_repair_shops",
        parent_id: 167
    },
    {
        id: 206,
        label: "Television Cable Services",
        value: "television_cable_services",
        parent_id: 167
    },
    {
        id: 207,
        label: "Recreational and Sporting Camps",
        value: "recreational_and_sporting_camps",
        parent_id: 167
    },
    {
        id: 208,
        label: "Barber and Beauty Shops",
        value: "barber_and_beauty_shops",
        parent_id: 167
    },
    {
        id: 209,
        label: "Agricultural Cooperatives",
        value: "agricultural_cooperatives",
        parent_id: 167
    },
    {
        id: 210,
        label: "Carpentry Contractors",
        value: "carpentry_contractors",
        parent_id: 167
    },
    {
        id: 211,
        label: "Wrecking and Salvaging Services",
        value: "wrecking_and_salvaging_services",
        parent_id: 167
    },
    {
        id: 212,
        label: "Automobile Towing Services",
        value: "automobile_towing_services",
        parent_id: 167
    },
    {
        id: 213,
        label: "Video Tape Rental Stores",
        value: "video_tape_rental_stores",
        parent_id: 167
    },
    {
        id: 214,
        label: "Miscellaneous Repair Shops",
        value: "miscellaneous_repair_shops",
        parent_id: 167
    },
    {
        id: 215,
        label: "Motor Homes and Parts",
        value: "motor_homes_and_parts",
        parent_id: 167
    },
    {
        id: 216,
        label: "Horse or Dog Racing",
        value: "horse_or_dog_racing",
        parent_id: 167
    },
    {
        id: 217,
        label: "Laundry Services",
        value: "laundry_services",
        parent_id: 167
    },
    {
        id: 218,
        label: "Electrical Contractors",
        value: "electrical_contractors",
        parent_id: 167
    },
    {
        id: 219,
        label: "Debt, Marriage, Personal Counseling Services",
        value: "debt_marriage_personal_counseling_service",
        parent_id: 167
    },
    {
        id: 220,
        label: "Air Conditioning and Refrigeration Repair Shops",
        value: "air_conditioning_and_refrigeration_repair_shops",
        parent_id: 167
    },
    {
        id: 221,
        label: "Credit Reporting Agencies",
        value: "credit_reporting_agencies",
        parent_id: 167
    },
    {
        id: 222,
        label: "Heating and Plumbing Contractors",
        value: "heating_and_plumbing_contractors",
        parent_id: 167
    },
    {
        id: 223,
        label: "Carpet and Upholstery Cleaning Services",
        value: "carpet_and_upholstery_cleaning_services",
        parent_id: 167
    },
    {
        id: 224,
        label: "Swimming Pools",
        value: "swimming_pools",
        parent_id: 167
    },
    {
        id: 225,
        label: "Roofing and Metal Work Contractors",
        value: "roofing_and_metal_work_contractors",
        parent_id: 167
    },
    {
        id: 226,
        label: "Internet Service Providers",
        value: "internet_service_providers",
        parent_id: 167
    },
    {
        id: 227,
        label: "Recreational Camps",
        value: "recreational_camps",
        parent_id: 167
    },
    {
        id: 228,
        label: "Masonry Contractors",
        value: "masonry_contractors",
        parent_id: 167
    },
    {
        id: 229,
        label: "Exterminating and Disinfecting Services",
        value: "exterminating_and_disinfecting_services",
        parent_id: 167
    },
    {
        id: 230,
        label: "Ambulance Services",
        value: "ambulance_services",
        parent_id: 167
    },
    {
        id: 231,
        label: "Funeral Services and Crematories",
        value: "funeral_services_and_crematories",
        parent_id: 167
    },
    {
        id: 232,
        label: "Metal Service Centres",
        value: "metal_service_centres",
        parent_id: 167
    },
    {
        id: 233,
        label: "Copying and Blueprinting Services",
        value: "copying_and_blueprinting_services",
        parent_id: 167
    },
    {
        id: 234,
        label: "Fuel Dispensers",
        value: "fuel_dispensers",
        parent_id: 167
    },
    {
        id: 235,
        label: "Welding Repair",
        value: "welding_repair",
        parent_id: 167
    },
    {
        id: 236,
        label: "Mobile Home Dealers",
        value: "mobile_home_dealers",
        parent_id: 167
    },
    {
        id: 237,
        label: "Concrete Work Contractors",
        value: "concrete_work_contractors",
        parent_id: 167
    },
    {
        id: 238,
        label: "Boat Rentals",
        value: "boat_rentals",
        parent_id: 167
    },
    {
        id: 239,
        label: "Personal Shoppers and Shopping Clubs",
        value: "personal_shoppers_and_shopping_clubs",
        parent_id: 167
    },
    {
        id: 240,
        label: "Door to Door Sales",
        value: "door_to_door_sales",
        parent_id: 167
    },
    {
        id: 241,
        label: "Travel Related Direct Marketing",
        value: "travel_related_direct_marketing",
        parent_id: 167
    },
    {
        id: 242,
        label: "Lottery and Betting",
        value: "lottery_and_betting",
        parent_id: 167
    },
    {
        id: 243,
        label: "Bands, Orchestras, and Miscellaneous Entertainers",
        value: "bands_orchestras_and_miscellaneous_entertainers",
        parent_id: 167
    },
    {
        id: 244,
        label: "Furniture Repair and Refinishing",
        value: "furniture_repair_and_refinishing",
        parent_id: 167
    },
    {
        id: 245,
        label: "Contractors",
        value: "contractors",
        parent_id: 167
    },
    {
        id: 246,
        label: "Direct Marketing and Subscription Merchants",
        value: "direct_marketing_and_subscription_merchants",
        parent_id: 167
    },
    {
        id: 247,
        label: "Typewriter Stores, Sales, Service and Rentals",
        value: "typewriter_stores_sales_service_and_rentals",
        parent_id: 167
    },
    {
        id: 248,
        label: "Recreation Services",
        value: "recreation_services",
        parent_id: 167
    },
    {
        id: 249,
        label: "Direct Marketing Insurance Services",
        value: "direct_marketing_insurance_services",
        parent_id: 167
    },
    {
        id: 250,
        label: "Business Services",
        value: "business_services",
        parent_id: 167
    },
    {
        id: 251,
        label: "Inbound Telemarketing Merchants",
        value: "inbound_telemarketing_merchants",
        parent_id: 167
    },
    {
        id: 252,
        label: "Public Warehousing",
        value: "public_warehousing",
        parent_id: 167
    },
    {
        id: 253,
        label: "Outbound Telemarketing Merchants",
        value: "outbound_telemarketing_merchants",
        parent_id: 167
    },
    {
        id: 254,
        label: "Clothing Rental Stores",
        value: "clothing_rental_stores",
        parent_id: 167
    },
    {
        id: 255,
        label: "Transportation Services",
        value: "transportation_services",
        parent_id: 167
    },
    {
        id: 256,
        label: "Electric Razor Stores",
        value: "electric_razor_stores",
        parent_id: 167
    },
    {
        id: 257,
        label: "Service Stations",
        value: "service_stations",
        parent_id: 167
    },
    {
        id: 258,
        label: "Photographic Studio",
        value: "photographic_studio",
        parent_id: 167
    },
    {
        id: 259,
        label: "Professional Services",
        value: "professional_services",
        parent_id: 167
    },
    {
        id: 260,
        label: "Housing",
        value: "housing",
        parent_id: 0
    },
    {
        id: 261,
        label: "Developer",
        value: "developer",
        parent_id: 260
    },
    {
        id: 262,
        label: "Facility Management",
        value: "facility_management",
        parent_id: 260
    },
    {
        id: 263,
        label: "RWA",
        value: "rwa",
        parent_id: 260
    },
    {
        id: 264,
        label: "Coworking",
        value: "coworking",
        parent_id: 260
    },
    {
        id: 265,
        label: "Real Estate Classifieds",
        value: "realestate_classifieds",
        parent_id: 260
    },
    {
        id: 266,
        label: "Space Rental",
        value: "space_rental",
        parent_id: 260
    },
    {
        id: 267,
        label: "Not for Profit",
        value: "not_for_profit",
        parent_id: 0
    },
    {
        id: 268,
        label: "Charity",
        value: "charity",
        parent_id: 267
    },
    {
        id: 269,
        label: "Educational",
        value: "educational",
        parent_id: 267
    },
    {
        id: 270,
        label: "Religious",
        value: "religious",
        parent_id: 267
    },
    {
        id: 271,
        label: "Personal",
        value: "personal",
        parent_id: 267
    },
    {
        id: 272,
        label: "Social",
        value: "social",
        parent_id: 0
    },
    {
        id: 273,
        label: "Matchmaking",
        value: "matchmaking",
        parent_id: 272
    },
    {
        id: 274,
        label: "Social Network",
        value: "social_network",
        parent_id: 272
    },
    {
        id: 275,
        label: "Messaging",
        value: "messaging",
        parent_id: 272
    },
    {
        id: 276,
        label: "Professional Network",
        value: "professional_network",
        parent_id: 272
    },
    {
        id: 277,
        label: "Neighbourhood Network",
        value: "neighbourhood_network",
        parent_id: 272
    },
    {
        id: 278,
        label: "Political Organizations",
        value: "political_organizations",
        parent_id: 272
    },
    {
        id: 279,
        label: "Automobile Associations and Clubs",
        value: "automobile_associations_and_clubs",
        parent_id: 272
    },
    {
        id: 280,
        label: "Country and Athletic Clubs",
        value: "country_and_athletic_clubs",
        parent_id: 272
    },
    {
        id: 281,
        label: "Associations and Membership",
        value: "associations_and_membership",
        parent_id: 272
    },
    {
        id: 282,
        label: "Media and Entertainment",
        value: "media_and_entertainment",
        parent_id: 0
    },
    {
        id: 283,
        label: "Video on Demand",
        value: "video_on_demand",
        parent_id: 282
    },
    {
        id: 284,
        label: "Music Streaming",
        value: "music_streaming",
        parent_id: 282
    },
    {
        id: 285,
        label: "Multiplex",
        value: "multiplex",
        parent_id: 282
    },
    {
        id: 286,
        label: "Content and Publishing",
        value: "content_and_publishing",
        parent_id: 282
    },
    {
        id: 287,
        label: "Ticketing",
        value: "ticketing",
        parent_id: 282
    },
    {
        id: 288,
        label: "News",
        value: "news",
        parent_id: 282
    },
    {
        id: 289,
        label: "Video Game Arcades",
        value: "video_game_arcades",
        parent_id: 282
    },
    {
        id: 290,
        label: "Video Tape Production and Distribution",
        value: "video_tape_production_and_distribution",
        parent_id: 282
    },
    {
        id: 291,
        label: "Bowling Alleys",
        value: "bowling_alleys",
        parent_id: 282
    },
    {
        id: 292,
        label: "Billiard and Pool Establishments",
        value: "billiard_and_pool_establishments",
        parent_id: 282
    },
    {
        id: 293,
        label: "Amusement Parks and Circuses",
        value: "amusement_parks_and_circuses",
        parent_id: 282
    },
    {
        id: 294,
        label: "Ticket Agencies",
        value: "ticket_agencies",
        parent_id: 282
    },
    {
        id: 295,
        label: "Gaming",
        value: "gaming",
        parent_id: 0
    },
    {
        id: 296,
        label: "Game Developer",
        value: "game_developer",
        parent_id: 295
    },
    {
        id: 297,
        label: "Esports",
        value: "esports",
        parent_id: 295
    },
    {
        id: 298,
        label: "Online Casino",
        value: "online_casino",
        parent_id: 295
    },
    {
        id: 299,
        label: "Fantasy Sports",
        value: "fantasy_sports",
        parent_id: 295
    },
    {
        id: 300,
        label: "Gaming Marketplace",
        value: "gaming_marketplace",
        parent_id: 295
    },
    {
        id: 301,
        label: "IT and Software",
        value: "it_and_software",
        parent_id: 0
    },
    {
        id: 302,
        label: "SaaS",
        value: "saas",
        parent_id: 301
    },
    {
        id: 303,
        label: "PaaS",
        value: "paas",
        parent_id: 301
    },
    {
        id: 304,
        label: "IaaS",
        value: "iaas",
        parent_id: 301
    },
    {
        id: 305,
        label: "Consulting and Outsourcing",
        value: "consulting_and_outsourcing",
        parent_id: 301
    },
    {
        id: 306,
        label: "Web Development",
        value: "web_development",
        parent_id: 301
    },
    {
        id: 307,
        label: "Technical Support",
        value: "technical_support",
        parent_id: 301
    },
    {
        id: 308,
        label: "Data Processing",
        value: "data_processing",
        parent_id: 301
    },
    {
        id: 309,
        label: "Food",
        value: "food",
        parent_id: 0
    },
    {
        id: 310,
        label: "Online Food Ordering",
        value: "online_food_ordering",
        parent_id: 309
    },
    {
        id: 311,
        label: "Restaurant",
        value: "restaurant",
        parent_id: 309
    },
    {
        id: 312,
        label: "Food Court",
        value: "food_court",
        parent_id: 309
    },
    {
        id: 313,
        label: "Catering",
        value: "catering",
        parent_id: 309
    },
    {
        id: 314,
        label: "Alcohol",
        value: "alcohol",
        parent_id: 309
    },
    {
        id: 315,
        label: "Restaurant Search and Booking",
        value: "restaurant_search_and_booking",
        parent_id: 309
    },
    {
        id: 316,
        label: "Dairy Products",
        value: "dairy_products",
        parent_id: 309
    },
    {
        id: 317,
        label: "Bakeries",
        value: "bakeries",
        parent_id: 309
    },
    {
        id: 318,
        label: "Utilities",
        value: "utilities",
        parent_id: 0
    },
    {
        id: 319,
        label: "Electricity",
        value: "electricity",
        parent_id: 318
    },
    {
        id: 320,
        label: "Gas",
        value: "gas",
        parent_id: 318
    },
    {
        id: 321,
        label: "Telecom",
        value: "telecom",
        parent_id: 318
    },
    {
        id: 322,
        label: "Water",
        value: "water",
        parent_id: 318
    },
    {
        id: 323,
        label: "Cable",
        value: "cable",
        parent_id: 318
    },
    {
        id: 324,
        label: "Broadband",
        value: "broadband",
        parent_id: 318
    },
    {
        id: 325,
        label: "DTH",
        value: "dth",
        parent_id: 318
    },
    {
        id: 326,
        label: "Internet Provider",
        value: "internet_provider",
        parent_id: 318
    },
    {
        id: 327,
        label: "Bill and Recharge Aggregators",
        value: "bill_and_recharge_aggregators",
        parent_id: 318
    },
    {
        id: 328,
        label: "Government",
        value: "government",
        parent_id: 0
    },
    {
        id: 329,
        label: "Central",
        value: "central",
        parent_id: 328
    },
    {
        id: 330,
        label: "State",
        value: "state",
        parent_id: 328
    },
    {
        id: 331,
        label: "Intra-Government Purchases",
        value: "intra_government_purchases",
        parent_id: 328
    },
    {
        id: 332,
        label: "Government Postal Services",
        value: "government_postal_services",
        parent_id: 328
    },
    {
        id: 333,
        label: "Logistics",
        value: "logistics",
        parent_id: 0
    },
    {
        id: 334,
        label: "Freight",
        value: "freight",
        parent_id: 333
    },
    {
        id: 335,
        label: "Courier",
        value: "courier",
        parent_id: 333
    },
    {
        id: 336,
        label: "Warehousing",
        value: "warehousing",
        parent_id: 333
    },
    {
        id: 337,
        label: "Distribution",
        value: "distribution",
        parent_id: 333
    },
    {
        id: 338,
        label: "End to End Logistics",
        value: "end_to_end_logistics",
        parent_id: 333
    },
    {
        id: 339,
        label: "Courier Services",
        value: "courier_services",
        parent_id: 333
    },
    {
        id: 340,
        label: "Tours and Travel",
        value: "tours_and_travel",
        parent_id: 0
    },
    {
        id: 341,
        label: "Aviation",
        value: "aviation",
        parent_id: 340
    },
    {
        id: 342,
        label: "Accommodation",
        value: "accommodation",
        parent_id: 340
    },
    {
        id: 343,
        label: "OTA",
        value: "ota",
        parent_id: 340
    },
    {
        id: 344,
        label: "Travel Agency",
        value: "travel_agency",
        parent_id: 340
    },
    {
        id: 345,
        label: "Tourist Attractions and Exhibits",
        value: "tourist_attractions_and_exhibits",
        parent_id: 340
    },
    {
        id: 346,
        label: "Timeshares",
        value: "timeshares",
        parent_id: 340
    },
    {
        id: 347,
        label: "Aquariums, Dolphinariums, and Seaquariums",
        value: "aquariums_dolphinariums_and_seaquariums",
        parent_id: 340
    },
    {
        id: 348,
        label: "Transport",
        value: "transport",
        parent_id: 0
    },
    {
        id: 349,
        label: "Cab Hailing",
        value: "cab_hailing",
        parent_id: 348
    },
    {
        id: 350,
        label: "Bus",
        value: "bus",
        parent_id: 348
    },
    {
        id: 351,
        label: "Train and Metro",
        value: "train_and_metro",
        parent_id: 348
    },
    {
        id: 352,
        label: "Automobile Rentals",
        value: "automobile_rentals",
        parent_id: 348
    },
    {
        id: 353,
        label: "Cruise Lines",
        value: "cruise_lines",
        parent_id: 348
    },
    {
        id: 354,
        label: "Parking Lots and Garages",
        value: "parking_lots_and_garages",
        parent_id: 348
    },
    {
        id: 355,
        label: "Transportation",
        value: "transportation",
        parent_id: 348
    },
    {
        id: 356,
        label: "Bridge and Road Tolls",
        value: "bridge_and_road_tolls",
        parent_id: 348
    },
    {
        id: 357,
        label: "Freight Transport",
        value: "freight_transport",
        parent_id: 348
    },
    {
        id: 358,
        label: "Truck and Utility Trailer Rentals",
        value: "truck_and_utility_trailer_rentals",
        parent_id: 348
    }                        
        
]