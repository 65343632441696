import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { showToast } from '../redux/action'
import { getApiReq, postApiReq } from '../utils/ApiHandler'
import { NavLink } from 'react-router-dom'
import { getAllCategories } from '../utils/basicReq'
import Cookies from 'js-cookie'
import Footer from '../component/Footer'

const MerchantsTab = () => {
  const navigate = useNavigate()

  const [merchantAllData, setMerchantAllData] = useState([])
  const [categories, setCategories] = useState(null)
  const [categoryValue, setCategoryValue] = useState(null)
  const [search, setSearch] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
    getAllMerchantData()
    setCategories(getAllCategories())
  }, [search])

  const getAllMerchantData = () => {
    getApiReq(`/admin/merchants?name=${search}`).then((res) => {
      if (res.status) {
        setMerchantAllData(res.data)
      } else {
        dispatch(showToast({ type: 'error', message: res.error }))
      }
    })
  }
  // --------------------------------Category Filter Data---------------------------------
  const getAllCategoryData = () => {
    getApiReq(`/admin/merchants?category=${categoryValue}`).then((res) => {
      if (res.status) {
        setMerchantAllData(res.data)
      } else {
        dispatch(showToast({ type: 'error', message: res.error }))
      }
    })
  }

  useEffect(() => {
    if (categoryValue) {
      getAllCategoryData()
    } else if (categoryValue === '') {
      getAllMerchantData()
    }
  }, [categoryValue])

  const handleChange = (event) => {
    const { value } = event.target
    setSearch(value)
  }

  // -------------------------------MerchantsStatus Api Calling----------------------------------
  // const handleStatus = (merchantid, merchantstatus) => {
  //   postApiReq(`/admin/merchant-status`, {
  //     id: merchantid,
  //     status: merchantstatus == 1 ? 0 : 1,
  //   }).then((res) => {
  //     if (res.status) {
  //       if (res.data.data.status == 1) {
  //         dispatch(
  //           showToast({
  //             type: "success",
  //             message: "merchant status active Successfully",
  //           })
  //         );
  //         getAllMerchantData();
  //       } else {
  //         dispatch(
  //           showToast({
  //             type: "success",
  //             message: "merchant status Inactive Successfully",
  //           })
  //         );
  //         getAllMerchantData();
  //       }
  //     } else {
  //       dispatch(showToast({ type: "error", message: res.error }));
  //     }
  //   });
  // };

  // --------------------LogOut----------------------------------------------------------------

  const handleLogout = () => {
    Cookies.remove('user')
    navigate('/')
  }

  return (
    <div className="md:text-[20px] text-[15px]">
      <div>
        <div className="w-full bottom-2 border-b-2  border-gray-400 bg-gray-700 text-white flex justify-between py-2">
          <div className="flex items-center tablet:ml-4 ml-2">
            <NavLink to="/merchantsqrcode">
              <img
                className=" tablet:w-[170px] w-[100px]"
                src="/image/Logo.png"
              />
            </NavLink>
          </div>

          <div className="flex items-center">
            <div className="tablet:mr-6 mr-2">
              <ul className="flex tablet:space-x-8 space-x-4 tablet:text-[25px] text-[16px]">
                <li>
                  <NavLink to="/couponstab">Coupons</NavLink>
                </li>
                <li>
                  <NavLink to="/coupons1">Create-Coupons</NavLink>
                </li>
                <li>
                  <NavLink to="/merchantstab">Merchants</NavLink>
                </li>
                <li>
                  <NavLink to="/payments">Payments</NavLink>
                </li>
                <li>
                  <NavLink to="/merchantsqrcode">QR code</NavLink>
                </li>
                <li>
                  <button onClick={handleLogout}>Logout</button>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="flex tablet:flex-row flex-col bg-[#CCCCCC] text-gray-700 text-[16px] justify-between">
          <div className="flex items-center tablet:space-x-8 space-x-4 tablet:ml-2 ">
            <div>
              <select
                className="focus:border-gray-100 focus:ring-gray-300 tablet:text-[18px] text-[15px]"
                onChange={(e) => setCategoryValue(e.target.value)}
              >
                <option value="">Select Categories</option>
                {getAllCategories()}
              </select>
            </div>

            <div className="flex p-4 justify-start bg-[#CCCCCC]">
              <div className="tablet:mr-2">
                <input
                  className="w-[220px] focus:border-gray-300 focus:ring-gray-400"
                  type="text"
                  name="search"
                  placeholder="Store/Brand Name"
                  onChange={handleChange}
                />
              </div>
            </div>

            {/* <div>
              <select className="focus:border-gray-100 focus:ring-gray-300 tablet:text-[18px] text-[15px]">
                <option>POC Name</option>
                <option>a</option>
                <option>b</option>
                <option>c</option>
                <option>d</option>
              </select>
            </div> */}
          </div>
        </div>

        <div className="w-full ">
          <table className="lg:w-[100%] tablet:w-[200%] w-[300%] table-fixed">
            <thead className="bg-gray-600 text-white sticky -top-[1px]">
              <tr>
                <th className=" w-[14.28%] font-bold border-2 p-3 border-l-0">
                  Merchants name
                </th>
                <th className=" w-[14.28%] font-bold border-2 p-3 ">Email</th>
                <th className=" w-[14.28%] font-bold border-2 p-3 ">
                  POC name
                </th>
                <th className=" w-[14.28%] font-bold border-2 p-3 ">
                  Contact no
                </th>
                <th className=" w-[14.28%] font-bold border-2 p-3 ">
                  Merchant URL
                </th>
                <th className=" w-[14.28%] font-bold border-2 p-3 ">Status</th>
                <th className=" w-[14.28%] font-bold border-2 p-3 border-r-0">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {merchantAllData?.length > 0 ? (
                merchantAllData?.map((item, index) => (
                  <tr key={index}>
                    <td className=" p-3  text-center border-r-2 border-b-2 border-gray-300  ">
                      {item.name}
                    </td>
                    <td className=" p-3  text-center border-r-2 border-b-2 border-gray-300  ">
                      {item.email}
                    </td>
                    <td className=" p-3  text-center border-r-2 border-b-2 border-gray-300  ">
                      {item.poc_name}
                    </td>
                    <td className=" p-3  text-center border-r-2 border-b-2 border-gray-300  ">
                      {item.phone}
                    </td>
                    <td className=" p-3  text-center border-r-2 border-b-2 border-gray-300  ">
                      {item.url ?? 'N/A'}
                    </td>

                    <td className=" p-3  text-center border-r-2 border-b-2 border-gray-300">
                      {/*  */}
                      {item.status == 1 ? 'Active' : 'Inactive'}
                      {/*  */}
                    </td>
                    <td className=" p-3  text-center border-r-2 border-b-2 border-gray-300  ">
                      <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md"
                        onClick={() => {
                          navigate(
                            `/editmerchantsdata/${item.uid}/${item.umid}`
                          )
                        }}
                      >
                        Edit
                      </button>

                      {/* <button
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md mt-2"
                      onClick={() => {
                        handleView(item.id);
                      }}
                    >
                      View
                    </button> */}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="tablet:text-[18px] text-[14px]">
                  <td
                    colSpan={7}
                    className=" text-center border-b-2 border-gray-300 py-2"
                  >
                    data not found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default MerchantsTab
