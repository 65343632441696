import axios from "axios";
import Cookies from "js-cookie";
import ENV from "./env";

const responseFormatter = (status, data, error) => {
  return { status, data, error };
};

// ----------------------------------Post api request common---------------------

export const postReq = async (url, data) => {
  const route_url = ENV.BASE_URL + url;
  return await axios
    .post(route_url, data, {
      headers: {
        Accept: "application/json",
      },
    })
    .then((response) => {
      return responseFormatter(true, response.data, null);
    })
    .catch((e) => {
      if (e.response.data.length > 0) {
        return responseFormatter(false, null, e.response.data);
      } else {
        return responseFormatter(false, null, e.response.data);
      }
    });
};

// ------------------------------PostApi request with Token--------------------------

export const postApiReq = async (url, data) => {
  const route_url = ENV.BASE_URL + url;
  var USER = null;
  if (Cookies.get("user")) {
    USER = JSON.parse(Cookies.get("user"));
  }
  return await axios
    .post(route_url, data, {
      headers: USER?.token
        ? {
            Accept: "application/json",
            Authorization: `Bearer ${USER.token}`,
          }
        : {
            Accept: "application/json",
          },
    })
    .then((response) => {
      return responseFormatter(true, response.data, null);
    })
    .catch((e) => {
      if (e?.response?.data?.length > 0) {
        return responseFormatter(false, null, e.response.data);
      } else {
        return responseFormatter(false, null, e.response.data);
      }
    });
};

export const patchApiReq = async (url, data) => {
  const route_url = ENV.BASE_URL + url;
  var USER = null;
  if (Cookies.get("user")) {
    USER = JSON.parse(Cookies.get("user"));
  }
  return await axios
    .patch(route_url, data, {
      headers: USER?.token
        ? {
            Accept: "application/json",
            Authorization: `Bearer ${USER.token}`,
          }
        : {
            Accept: "application/json",
          },
    })
    .then((response) => {
      return responseFormatter(true, response.data, null);
    })
    .catch((e) => {
      if (e?.response?.data?.length > 0) {
        return responseFormatter(false, null, e.response.data);
      } else {
        return responseFormatter(false, null, e.response.data);
      }
    });
};

//--------------------------------get Api request--------------------------------------
export const getReq = async (url) => {
  const route_url = ENV.BASE_URL + url;
  return await axios
    .get(route_url, {
      headers: {
        Accept: "application/json",
      },
    })
    .then((response) => {
      return responseFormatter(true, response.data, null);
    })
    .catch((e) => {
      if (e.response.data.length > 0) {
        return responseFormatter(false, null, e.response.data);
      } else {
        return responseFormatter(false, null, e.response.data);
      }
    });
};

//------------------------------------- get api request with Token-------------------------------

export const getApiReq = async (url) => {
  var USER = null;
  if (Cookies.get("user")) {
    USER = JSON.parse(Cookies.get("user"));
  }
  const route_url = ENV.BASE_URL + url;
  return await axios
    .get(route_url, {
      headers: USER?.token
        ? {
            Accept: "application/json",
            Authorization: `Bearer ${USER.token}`,
          }
        : {
            Accept: "application/json",
          },
    })
    .then((response) => {
      return responseFormatter(true, response.data, null);
    })
    .catch((e) => {
      if (e.response.data.length > 0) {
        return responseFormatter(false, null, e.response.data);
      } else {
        return responseFormatter(false, null, e.response.data);
      }
    });
};
