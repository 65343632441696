const dev = {
  BASE_URL:
    // process.env.NODE_ENV === "development"
    //   ? "http://localhost:8000"
    //   : 
      
      "https://api.inburg.com",
  IMAGE_URL: 'https://api.inburg.com/',
};


const ENV = dev;

export default ENV;