import * as yup from 'yup'
const name = /^[a-zA-Z]+$/
const number = /^[0-9\b]+$/
const gst =
  /^([0-9]{2}[a-zA-Z]{4}([a-zA-Z]{1}|[0-9]{1})[0-9]{4}[a-zA-Z]{1}([a-zA-Z]|[0-9]){3}){0,15}$/

// --------------------LoginValidation------------------------------------------------------------
export const LoginValidation = yup.object().shape({
  email: yup
    .string()
    .required('Please enter email Address.')
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Please enter valid email.'
    ),
  password: yup.string().required('Please enter password.'),
})

// --------------------BrandValidation -------------------------------------------------------------
export const MerchantsValidation = yup.object().shape({
  email: yup
    .string()
    .email('Please enter valid email.')
    .required('Please enter email Address.'),
  package: yup.string().required('Please enter package.'),
  poc_name: yup.string().required('Please enter brand poc name.'),
  status: yup.string().required('Please enter brand status.'),

  phone: yup.string().required('Please enter contact number.'),
  // .matches(number, 'Please enter only number')
  // .min(10, 'Please enter valid number')
  // .max(12, 'max limit is 12'),

  category: yup.string().required('Please enter brand category.'),

  business_pan: yup.string().required('Please enter pan number.'),
  legal_business_name: yup
    .string()
    .required('Please enter legal business name.'),
  contact_name: yup.string().required('Please enter contact name.'),
  brand_name: yup.string().required('Please enter brand name.'),

  gst: yup
    .string()
    .required('Please enter gst number.')
    .matches(gst, 'Only alphabets and number are acceptable')
    .max(16, 'max limit is 16'),

  upi: yup.string().required('Please enter upi-id.'),
  uid: yup.string().required('Please enter uid.'),
  umid: yup.string().required('Please enter umid.'),

  'address[city]': yup.string().required('Please enter city.'),
  'address[state]': yup.string().required('Please enter state.'),
  'address[street1]': yup.string().required('Please enter street 1.'),
  'address[street2]': yup.string().required('Please enter street 1.'),
  'address[postal_code]': yup
    .string()
    .required('Please enter your postal code.'),
  'address[country]': yup.string().required('Please enter your country.'),
})

export const Coupons1Validation = yup.object().shape({
  merchantId: yup.string().required('Please select merchant name'),
  couponCount: yup.string().required('Please enter total coupon'),
  couponStart: yup.string().required('Please enter start date'),
  couponExpiry: yup.string().required('Please enter expiry date'),
})
export const Coupons2Validation = yup.object().shape({
  couponDiscount: yup.string().required('please enter coupon discount'),
  minAmount: yup.string().required('Please enter min amount'),
  maxAmount: yup.string().required('Please enter max amount'),
  couponType: yup.string().required('Please select offer'),
  inburgPercent: yup.string().required('Please enter inburg percent'),
})

export const StartEndDateValidation = yup.object().shape({
  Start: yup.string().required('Please enter start date'),
  End: yup.string().required('Please enter end date'),
})
