import { Modal } from '@mui/material'
import { useEffect, useState } from 'react'
import { FormField, TextInput } from './Components'
import { postApiReq } from '../../utils/ApiHandler'

export default function BankData({ errors, id, defaultValues }) {
  const [open, setOpen] = useState(false)

  async function handleRequestProduct() {
    const res = await postApiReq(`/admin/merchants/accounts/${id}/products`, {})

    console.log(res)
  }

  useEffect(() => {
    handleRequestProduct()
  }, [])

  return (
    <>
      <div className="border-b-2 border-gray-400 tablet:mx-0 mx-4">
        <p className="font-bold mb-2 tablet:text-left text-center tablet:text-[18px] text-[16px]">
          Bank Details
        </p>
      </div>

      <FormField label="Account Number" error={errors.account_number}>
        <TextInput
          placeholder="xxxx-xxxx-xxxx"
          name="account_number"
          required
          defaultValue={defaultValues.account_number}
        />
      </FormField>

      <FormField label="IFSC CODE" error={errors.ifsc_code}>
        <TextInput
          placeholder="19230"
          name="ifsc_code"
          required
          defaultValue={defaultValues.ifsc_code}
        />
      </FormField>

      <FormField
        label="Beneficiary Name (Same as Legal Business Name)"
        error={errors.beneficiary_name}
      >
        <TextInput
          placeholder="ABC corp"
          name="beneficiary_name"
          required
          defaultValue={defaultValues.beneficiary_name}
        />
      </FormField>

      <Modal open={open} onClose={() => setOpen(false)}>
        <div>d</div>
      </Modal>
    </>
  )
}
