import { useEffect, useState } from 'react'
import { businessCategories } from '../../data/businessCategoriesData'
import {
  FileInput,
  FormField,
  RadioInput,
  SelectInput,
  TextInput,
} from './Components'
import { useParams } from 'react-router-dom'

export default function MerchantsDataStep1({ mode, errors, defaultValues }) {
  const { uid, umid } = useParams()

  const categories = businessCategories.filter(
    (category) => category.parent_id === 0
  )

  const defaultAddress = defaultValues?.address
  const defaultLegalInfo = defaultValues?.legal_info

  const defaultCategory = businessCategories.find(
    (category) => category.value === defaultValues.category
  );

  const [category, setCategory] = useState({
    id: 1,
    label: 'Financial Services',
    value: 'financial_services',
    parent_id: 0,
  });

  useEffect(()=> {

    if(defaultCategory) {
      setCategory({...defaultCategory})

    }

  }, [defaultCategory])

  const subCategories = businessCategories.filter(
    (subcategory) => subcategory.parent_id === category.id
  )

  return (
    <>
      <div className="border-b-2 border-gray-400 tablet:mx-0 mx-4">
        <p className="font-bold mb-2 tablet:text-left text-center tablet:text-[18px] text-[16px]">
          Create New Merchant Or Store Entity
        </p>
      </div>

      <FormField label={'Email address'} error={errors.email}>
        <TextInput
          type="email"
          placeholder="name@example.com"
          name="email"
          defaultValue={defaultValues?.email}
        />
      </FormField>

      <FormField label={'Phone'} error={errors.phone}>
        <TextInput
          type="text"
          placeholder="+912212345678"
          name="phone"
          defaultValue={defaultValues?.phone}
        />
      </FormField>

      <FormField label={'Logo'} error={errors.logo}>
        <FileInput accept={'image/*'} name="logo" />
      </FormField>

      <FormField
        label={'Legal Business Name'}
        error={errors.legal_business_name}
      >
        <TextInput
          type="text"
          placeholder="ABC Corp"
          name="legal_business_name"
          defaultValue={defaultValues?.name}
        />
      </FormField>

      <FormField label={'UID'} error={errors.uid}>
        <TextInput
          type="text"
          placeholder="123"
          name="uid"
          value={uid}
          disabled
        />
      </FormField>

      <FormField label={'UMID'} error={errors.umid}>
        <TextInput
          type="text"
          placeholder="123"
          name="umid"
          value={umid}
          disabled
        />
      </FormField>

      { mode === 'edit' && 
        <FormField label={'URL'} error={errors.url}>
          <TextInput
            type="text"
            placeholder="https://inburg.com/merchants"
            name="url"
            value={defaultValues.url}
            disabled
          />
        </FormField>
      }

      <FormField label={'Brand Name'} error={errors.brand_name}>
        <TextInput
          type="text"
          placeholder="Name"
          name="brand_name"
          defaultValue={defaultValues?.legal_info?.brand_name}
        />
      </FormField>

      <FormField label={'Status'} error={errors.status}>
        <RadioInput
          items={[
            { label: 'Active', value: '1' },
            { label: 'Inactive', value: '0' },
          ]}
          name="status"
          defaultChecked={String(defaultValues?.status)}
        />
      </FormField>

      <FormField label={'Business Type'} error={errors.business_type}>
        <SelectInput
          name="business_type"
          items={business_type}
          defaultValue={defaultValues?.legal_info?.business_type}
        />
      </FormField>

      <FormField label={'Contact Person Name'} error={errors.contact_name}>
        <TextInput
          type="text"
          placeholder="John Doe"
          name="contact_name"
          defaultValue={defaultValues?.legal_info?.contact_name}
        />
      </FormField>

      <FormField label={'Package'} error={errors.package}>
        <SelectInput
          name="package"
          items={packages}
          defaultValue={defaultValues?.package}
        />
      </FormField>

      <FormField label={'Category'} error={errors.category}>
        <SelectInput
          defaultValue={defaultValues?.category}
          onChange={(e) => {
            const category = businessCategories.find(
              (category) => category.value === e.target.value
            )
            setCategory(category)
          }}
          name="category"
          items={categories.map((category) => {
            return {
              label: category.label,
              value: category.value,
            }
          })}
        />
      </FormField>

      <FormField label={'Sub Category'} error={errors.subcategory}>
        <SelectInput
          name="subcategory"
          defaultValue={defaultValues?.subcategory}
          items={subCategories.map((category) => {
            return {
              label: category.label,
              value: category.value,
            }
          })}
        />
      </FormField>

      <FormField label={'Address'}>
        <FormField error={errors?.address?.street1}>
          <TextInput
            type="text"
            placeholder="Street 1"
            name="address[street1]"
            defaultValue={defaultAddress?.street1}
          />
        </FormField>

        <FormField error={errors?.address?.street2}>
          <TextInput
            type="text"
            placeholder="Street 2"
            name="address[street2]"
            defaultValue={defaultAddress?.street2}
          />
        </FormField>

        <FormField error={errors?.address?.city}>
          <TextInput
            type="text"
            placeholder="City"
            name="address[city]"
            defaultValue={defaultAddress?.city}
          />
        </FormField>

        <FormField error={errors?.address?.state}>
          <TextInput
            type="text"
            placeholder="State"
            name="address[state]"
            defaultValue={defaultAddress?.state}
          />
        </FormField>

        <FormField error={errors?.address?.postal_code}>
          <TextInput
            type="text"
            placeholder="Postal Code"
            name="address[postal_code]"
            defaultValue={defaultAddress?.postal_code}
          />
        </FormField>

        <FormField error={errors?.address?.country}>
          <TextInput
            type="text"
            placeholder="Country(IN)"
            name="address[country]"
            value="IN"
            disabled
          />
        </FormField>
      </FormField>

      <FormField label={'Legal Info'}>
        <FormField error={errors.business_pan}>
          <TextInput
            type="text"
            placeholder="Business PAN"
            name="business_pan"
            defaultValue={defaultLegalInfo?.pan}
          />
        </FormField>

        <FormField error={errors.gst}>
          <TextInput
            type="text"
            placeholder="GST"
            name="gst"
            defaultValue={defaultLegalInfo?.gst}
          />
        </FormField>

        <FormField error={errors.poc_name}>
          <TextInput
            type="text"
            placeholder="POC Name"
            name="poc_name"
            defaultValue={defaultValues?.poc_name}
          />
        </FormField>

        <FormField error={errors.upi}>
          <TextInput
            type="text"
            placeholder="UPI"
            name="upi"
            defaultValue={defaultValues?.upi}
          />
        </FormField>
      </FormField>
    </>
  )
}

const business_type = [
  {
    value: 'llp',
    label: 'LLP',
  },
  {
    value: 'ngo',
    label: 'NGO',
  },
  {
    value: 'other',
    label: 'Other',
  },
  {
    value: 'partnership',
    label: 'Partnership',
  },
  {
    value: 'proprietorship',
    label: 'Proprietorship',
  },
  {
    value: 'public_limited',
    label: 'Public Limited',
  },
  {
    value: 'private_limited',
    label: 'Private Limited',
  },
  {
    value: 'Trust,society',
    label: 'Trust/Society',
  },
  {
    value: 'not_yet_registered',
    label: 'Not Yet Registered',
  },
  {
    value: 'not_yet_registered',
    label: 'Not Yet Registered',
  },
  {
    value: 'educational_institutes',
    label: 'Educational Institutes',
  },
]

const packages = [
  { value: 'free', label: 'Free' },
  { value: 'starter', label: 'Starter' },
  { value: 'advance', label: 'Advance' },
  { value: 'elite', label: 'Elite' },
]
