import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getApiReq } from "../utils/ApiHandler";
import { showToast } from "../redux/action";
import { NavLink, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Footer from "../component/Footer";

const CouponsTab = () => {
  const [getAllCoupons, setGetAllCoupons] = useState();
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();

  const getCoupon = () => {
    console.log(search, 'search')
    getApiReq(`/admin/get-coupon-details?search=${search}`).then((res) => {
      if (res.status) {
        console.log(res.data.data)
        setGetAllCoupons(res.data.data);
      } else {
        dispatch(showToast({ type: "error", message: res.error }));
      }
    });
  }
  useEffect(() => {
    getCoupon()
  }, [search]);

  const handleChange = (event) => {
    const { value } = event.target;
    setSearch(value);

  };

  // const getAllCoponsData = () => {
  //   getApiReq(`/admin/get-coupons`).then((res) => {
  //     if (res.status) {
  //       handleCoponData(res.data.data);
  //     } else {
  //       dispatch(showToast({ type: "error", message: res.error }));
  //     }
  //   });
  // };

  // const handleCoponData = (data) => {
  //   let tempArray = [];
  //   let tempObject = [];
  //   data?.map((item) => {
  //     if (!tempArray.includes(item.merchant_name)) {
  //       tempArray.push(item.merchant_name)
  //       tempObject.push({ merchant_name: item.merchant_name, merchant_id: item.merchant_id, offer_id: item.offer_id })
  //     }
  //     else {
  //       return
  //     }
  //   })
  //   setGetAllCoupons(tempObject)
  // }


  const handleLogout = () => {
    Cookies.remove('user');
    navigate('/')
  }

  // -------------------------------Post api call from particular id-------------------
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex justify-between bg-gray-700 items-center py-2">
        <div className="tablet:ml-4 ml-2 tablet:w-[170px] w-[150px]">
          <NavLink to="/merchantsqrcode"><img className="w-full" src="/image/Logo.png" /></NavLink>
        </div>
        <div className="flex flex-wrap justify-end tablet:justify-start gap-3 tablet:gap-8 bg-gray-700 text-white tablet:text-[25px] text-[16px] tablet:mr-6 mr-4">
          <NavLink to="/couponstab">Coupons</NavLink>
          <NavLink to="/coupons1">Create-Coupons</NavLink>
          <NavLink to="/merchantstab">Merchants</NavLink>
          <NavLink to="/payments">Payments</NavLink>
          <NavLink to="/merchantsqrcode">QR code</NavLink>
          <button onClick={handleLogout}>Logout</button>
        </div>
      </div>

      <div className="flex p-4 justify-start bg-[#CCCCCC]">
        <div className="tablet:mr-2">
          <input
            className="w-[220px] focus:border-gray-300 focus:ring-gray-400"
            type="text"
            name="merchant_search"
            placeholder="Search merchant"
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="w-full">
        <table className="tablet:w-[200%] lg:w-full w-[300%]" >
          <thead className="sticky top-0">
            <tr className="bg-gray-600 text-white tablet:text-[20px] text-[16px]">
              <th className="w-1/10 border-l-2 border-b-2 p-3">
                Merchants Name
              </th>

              <th className="w-1/10 border-l-2 border-b-2 p-3">
                Total Coupon
              </th>

              <th className="w-1/10 border-l-2 border-b-2 p-3">
                Coupons Issued
              </th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">
                Coupons Redeemed
              </th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">
                Coupons Remaining
              </th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">
                Total Sales Value <span> (&#8377;) </span>
              </th>
              <th className="w-1/10 border-l-2 border-b-2 p-3">
                Total Commission <span> (&#8377;) </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {
              (getAllCoupons?.length > 0) ?

                getAllCoupons?.map((item, index) => {
                  return (
                    <tr className="tablet:text-[18px] text-[14px]" key={index}>

                      <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300 ">
                        <button className="text-sky-700 capitalize text-[19px] underline hover:text-sky-900 underline-offset-2" onClick={() => navigate(`/couponsviewpage/${item.id}`)}>{item.name}</button>
                      </td>
                      <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">{(item.offer_coupon_count) ? item.offer_coupon_count : 0}</td>
                      <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">{(item.total_coupon) ? item.total_coupon : 0}</td>
                      <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">{(item.redeemed_coupon) ? item.redeemed_coupon : 0}</td>
                      <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">{(item.remaning_coupon) ? item.remaning_coupon : 0}</td>
                      <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">{(item.total_sale) ? item.total_sale : 0}</td>
                      <td className=" p-3 h-14 text-center border-r-2 border-b-2 border-gray-300  ">{(item.total_commission) ? item.total_commission : 0}</td>
                    </tr>
                  );
                }) :
                <tr className="tablet:text-[18px] text-[14px]">
                  <td colSpan={7} className=" text-center border-b-2 border-gray-300 py-2">Data Not Found</td>
                </tr>}
          </tbody>
        </table>
      </div>
    </div >
  );
};

export default CouponsTab;
