export function FormField({ children, error, label }) {
  const inputContent = (
    <div className="w-full">
      <div className={'w-full flex flex-col gap-3'}>{children}</div>
      <span className="text-red-600 text-[14px] leading-6">{error}</span>
    </div>
  )

  if (!label) return inputContent
  return (
    <div className="gap-x-6 flex tablet:flex-row tablet:mx-0 mx-4 flex-col">
      <label className="tablet:w-1/3 flex-shrink-0 tablet:leading-9 leading-7">
        {label} :
      </label>

      {inputContent}
    </div>
  )
}

export function TextInput(props) {
  return (
    <input
      type="text"
      className={
        'tablet:text-[16px] text-[14px] w-full border border-solid border-gray-300 disabled:opacity-50 disabled:border-transparent'
      }
      {...props}
    />
  )
}

export function RadioInput({ items, ...props }) {
  return (
    <div className={'flex items-center gap-4'}>
      {items.map((item) => {
        return (
          <label key={item.value} className={'flex items-center gap-1'}>
            <input
              type="radio"
              className="tablet:text-[16px] text-[14px] border-none"
              {...props}
              checked={props.defaultChecked === item.value}
            />

            <span>{item.label}</span>
          </label>
        )
      })}
    </div>
  )
}

export function FileInput(props) {
  return (
    <input
      type="file"
      className="tablet:text-[16px] text-[14px] border-none w-full"
      {...props}
    />
  )
}

export function SelectInput({ items, ...props }) {
  return (
    <select
      className="w-full border-gray-300 tablet:text-[16px] text-[14px]"
      {...props}
    >
      {items.map((item) => {
        return (
          <option key={item.value} value={item.value} selected={props.defaultValue === item.value}>
            {item.label}
          </option>
        )
      })}
    </select>
  )
}
